<template>
  <div v-if="loaded">
    <cui-acl  :roles="['tasksAll', data.type]" redirect>
      <h2>{{ data.title }}</h2>
      <div v-html="data.description"></div>
      <a :href="attachment.url" target="_blank" v-if="attachment">{{ attachment.name }}</a><br>
      <div v-if="attachments">
        <div v-for="attachment in attachments" :key="attachment.name">
          <a :href="attachment.url" target="_blank" v-if="attachment">{{ attachment.name }}</a><br>
        </div>
      </div>
      <list :list="subTasks" v-if="subTasks.length" :action="subTasksAction" :actionIcon="subTasksActionIcon" @launchAction="subTaskActionCalled"/>
      <div v-if="callbacks">
        <a-button
          :type="callback.type === 'CompleteTask' ? 'primary' : 'secondary'"
          class="mt-3"
          v-for="(callback, i) in callbacks"
          :key="i"
          @click="cbf(callback.fn, callback.data, callback.type, i)"
          :loading="loading"
        >{{ callback.name }}</a-button>
        <a-button
          v-if="callbackDoneNoDecline < 1"
          type="secondary"
          class="mt-3"
          @click="taskDone"
        >Avvis og slett forespørsel</a-button>
      </div>
      <a-button type="primary"  class="mt-3" @click="taskDone" v-if="!callbackDone">Gjennomført</a-button>
      <div class="mt-3" v-if="docxpreview">
      </div>
    </cui-acl>
  </div>
</template>

<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import list from '@/components/custom/visual/lists/List1'

import moment from 'moment'
import { firestore, functions, storage } from '@/services/firebase'
import { openNotification } from '@/services/powerAutomate'

export default {
  components: {
    list,
    cuiAcl,
  },
  data() {
    return {
      attachment: {},
      attachments: [],
      loaded: false,
      subTasks: [],
      taskRef: '',
      callbacks: [],
      loading: false,
      subTasksActionIcon: '',
      subTasksActionfn: '',
      callbackDone: false,
      callbackDoneNoDecline: 0,
      docxpreview: false,
    }
  },
  methods: {
    moment,
    deleteTask() {
      const listRef = storage.ref('/tasks/tasksAttachments')
      if (this.attachment) {
        if (this.attachment.type === 'file') {
          listRef.child(this.attachment.name).delete()
        }
      }
      if (this.attachments) {
        this.attachments.forEach(attachment => {
          if (attachment.type === 'file') {
            listRef.child(attachment.name).delete()
          }
        })
      }
      if (this.subTasks) {
        this.subTasks.forEach(task => {
          this.subTaskDone(task.id)
        })
      }
    },
    taskDone() {
      firestore.collection('tasks').doc(this.$route.params.id).delete()
      this.deleteTask()
      this.$router.push({ path: '/tasks' })
    },
    subTaskActionCalled(id) {
      this[this.subTasksActionfn](id)
    },
    subTaskDone(id) {
      this.taskRef.collection('subtasks').doc(id).delete()
    },
    cbf(fn, data, type, i) {
      console.log(fn)
      const call = functions.httpsCallable(String(fn))
      this.loading = true
      call(data).then(res => {
        console.log(data)
        if (type === 'CompleteTask') {
          this.taskDone()
        }
        openNotification(res.data.text)
      }).catch(err => {
        console.log(err)
        openNotification(err.message)
      }).finally(() => {
        this.loading = false
      })
    },
    deleteAttachment(attachment) {
      const listRef = storage.ref('/tasks/tasksAttachments')
      listRef.child(attachment.name).delete()
      this.taskRef.update({
        attachments: firestore.FieldValue.arrayRemove(attachment),
      })
    },
  },
  mounted() {
    this.loaded = false
    this.taskRef = firestore.collection('tasks').doc(this.$route.params.id)
    this.taskRef
      .get()
      .then(doc => {
        if (!doc.exists) {
          return this.$router.push({ path: '/tasks' })
        }
        console.log(doc.data())
        this.data = doc.data()
        this.attachment = doc.data().attachment
        this.attachments = doc.data().attachments
        this.callbacks = doc.data().callbacks
        if (this.callbacks) {
          this.callbacks.forEach(callback => {
            if (callback.type === 'CompleteTask') {
              this.callbackDone = true
            }
            if (callback.type === 'DeclineTask') {
              this.callbackDoneNoDecline = this.callbackDoneNoDecline + 1
            }
          })
        }
        this.subTasksAction = doc.data().subTasksAction
        this.subTasksActionfn = doc.data().subTasksActionfn ? doc.data().subTasksActionfn : 'subTaskDone'
        this.subTasksActionIcon = doc.data().subTasksActionIcon
        if (doc.data().hasSubtasks) {
          console.log('check')
          this.taskRef.collection('subtasks')
            .onSnapshot(snapshot => {
              this.subTasks = []
              snapshot.forEach(doc => {
                this.subTasks.push({
                  title: doc.data().title,
                  description: doc.data().description,
                  img: doc.data().img,
                  icon: doc.data().icon,
                  id: doc.id,
                })
              })
            })
        }
        this.loaded = true
      })
  },
}
</script>
